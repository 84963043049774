// import Freezeframe from 'freezeframe';

export default class ProjectGifEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.projectPage')) {
      return false;
    }
    // const gifA = document.querySelector('.freezeframe');

    // gifA.addEventListener(
    //   'mouseover',
    //   function (e) {
    //     // 追加するimgを生成
    //     console.log(e.currentTarget);
    //     var img = e.currentTarget.createElement('img');
    //     img.src = '/assets/images/dummy_project.gif';
    //     wrapper.textContent = null;
    //     wrapper.appendChild(img);
    //   },
    //   false
    // );
    // // const logo = new Freezeframe('.freezeframe', {
    //   // trigger: false,
    // });

    // logo.toggle();
  }
}
