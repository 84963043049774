import Swiper, { Scrollbar, Mousewheel } from 'swiper';
Swiper.use([Scrollbar, Mousewheel]);
export default class MembersEvent {
  constructor() {
    this.init();
  }

  init() {
    if (!document.querySelector('.topMembersSlide')) {
      return false;
    }
    Scrollbar;
    const swiper = new Swiper('.swiper', {
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      mousewheel: {
        forceToAxis: true,
      },
      slidesPerView: 1.5,
      spaceBetween: 8,
      breakpoints: {
        500: {
          slidesPerView: 1.3,
          spaceBetween: 8,
        },
        600: {
          slidesPerView: 2.1,
          spaceBetween: 8,
        },
        768: {
          slidesPerView: 'auto',
          spaceBetween: 8,
        },
      },
    });
    swiper.on('slideChange', function () {
      console.log('*** mySwiper.realIndex', swiper.realIndex);
    });

    const topMemberModal = document.querySelector('.topMemberModal');
    const close = document.querySelector('.js-memberModalClose');
    const outer = document.querySelector('.topMemberModalContentOuter');

    close.addEventListener('click', () => {
      closeEvent();
    });
    outer.addEventListener('click', () => {
      closeEvent();
    });

    let modalClickIndex = 0;

    const cards = document.querySelectorAll('.js-MembersCard');
    for (let i = 0; i < cards.length; i++) {
      cards[i].addEventListener('click', () => {
        const modalIndex = cards[i].dataset.slideIndex;
        topMemberModal.classList.add('Act');
        setTimeout(() => {
          topMemberModal.classList.add('ActInner');
        }, 100);

        setTimeout(() => {
          memberModals[modalIndex].classList.add('ActInner');
          modalClickIndex = modalIndex;
        }, 150);
        memberModals[modalIndex].classList.add('Act');
      });
    }

    const memberModals = document.querySelectorAll('.js-memberModalContent');
    for (let i = 0; i < memberModals.length; i++) {
      memberModals[i].addEventListener('click', (e) => {
        e.stopPropagation();
      });
    }

    const NavLs = document.querySelectorAll('.topMemberModalBodyNavL');
    const NavRs = document.querySelectorAll('.topMemberModalBodyNavR');

    for (let i = 0; i < NavLs.length; i++) {
      NavLs[i].addEventListener('click', (e) => {
        e.stopPropagation();
        let index = Number(modalClickIndex) - 1;
        if (index < 0) {
          index = memberModals.length - 1;
        }
        switchModal(index);
      });
    }
    for (let i = 0; i < NavRs.length; i++) {
      NavRs[i].addEventListener('click', (e) => {
        e.stopPropagation();
        let index = Number(modalClickIndex) + 1;
        if (index > memberModals.length - 1) {
          index = 0;
        }
        switchModal(index);
      });
    }

    function switchModal(newIndex) {
      memberModals[modalClickIndex].classList.remove('Act');
      memberModals[modalClickIndex].classList.remove('ActInner');
      memberModals[newIndex].classList.add('Act');
      setTimeout(() => {
        memberModals[newIndex].classList.add('ActInner');
      }, 100);
      modalClickIndex = newIndex;
    }

    function closeEvent() {
      memberModals[modalClickIndex].classList.remove('ActInner');
      setTimeout(() => {
        topMemberModal.classList.add('ActOut');
      }, 250);
      setTimeout(() => {
        topMemberModal.classList.remove('Act');
        topMemberModal.classList.remove('ActOut');
      }, 500);
      setTimeout(() => {
        for (let i = 0; i < memberModals.length; i++) {
          memberModals[i].classList.remove('Act');
          memberModals[i].classList.remove('ActInner');
        }
      }, 500);

      modalClickIndex = 0;
    }
  }
}
