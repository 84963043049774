import ScrollMagic from 'scrollmagic';

export default class ScrollMagicProject {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.topProject')) {
      return false;
    }
    let controller = new ScrollMagic.Controller();
    let prog = 0;
    const projectCards = document.querySelectorAll('.c_projectCard');
    const cat = document.querySelector('.topProjectMovieTitleCat');
    const title = document.querySelector('.topProjectMovieTitleName');
    const view = document.querySelector('.topProjectMovieLink');

    const initScene = () => {
      const p = new ScrollMagic.Scene({
        triggerElement: '#pinProject',
        triggerHook: 0,
        duration: '300%',
      })
        .setPin('#pinProject')
        .on('enter', function (e) {
          // console.log(e);
          prog = e.progress;
          projectCardsEvent(prog);
        })
        .on('progress', function (e) {
          // console.log(e.progress);
          prog = e.progress;
          projectCardsEvent(prog);
        })
        .on('leave', function (e) {})
        .addTo(controller);
    };

    const projectCardsEvent = (prog) => {
      if (prog < 0.33) {
        projectCards[0].classList.remove('Gone');
        projectCards[0].classList.remove('Hide');
        projectCards[0].classList.remove('Stay');

        projectCards[1].classList.remove('Gone');
        projectCards[1].classList.remove('Hide');
        projectCards[1].classList.add('Stay');

        if (projectCards.length > 2) {
          projectCards[2].classList.remove('Gone');
          projectCards[2].classList.add('Hide');
          projectCards[2].classList.remove('Stay');
        }

        cat.innerHTML = projectCards[0].dataset.cat;
        title.innerHTML = projectCards[0].dataset.title;
        view.setAttribute('href', projectCards[0].dataset.url);
      }

      if (prog < 0.66 && prog >= 0.33) {
        projectCards[0].classList.add('Gone');
        projectCards[0].classList.remove('Hide');
        projectCards[0].classList.remove('Stay');

        projectCards[1].classList.remove('Gone');
        projectCards[1].classList.remove('Hide');
        projectCards[1].classList.remove('Stay');

        if (projectCards.length > 2) {
          projectCards[2].classList.remove('Gone');
          projectCards[2].classList.remove('Hide');
          projectCards[2].classList.add('Stay');
        }

        cat.innerHTML = projectCards[1].dataset.cat;
        title.innerHTML = projectCards[1].dataset.title;
        view.setAttribute('href', projectCards[1].dataset.url);
      }

      if (prog >= 0.66) {
        if (projectCards.length > 2) {
          projectCards[0].classList.add('Gone');
          projectCards[0].classList.remove('Hide');
          projectCards[0].classList.remove('Stay');

          projectCards[1].classList.add('Gone');
          projectCards[1].classList.remove('Hide');
          projectCards[1].classList.remove('Stay');

          projectCards[2].classList.remove('Gone');
          projectCards[2].classList.remove('Hide');
          projectCards[2].classList.remove('Stay');

          cat.innerHTML = projectCards[2].dataset.cat;
          title.innerHTML = projectCards[2].dataset.title;
          view.setAttribute('href', projectCards[2].dataset.url);
        }
      }
    };

    if (window.matchMedia('(max-width: 768px)').matches) {
    } else {
      initScene();
    }
    window.addEventListener('resize', () => {
      if (controller) controller = controller.destroy(true);
      if (window.matchMedia('(max-width: 768px)').matches) {
      } else {
        controller = new ScrollMagic.Controller();
        initScene();
      }
    });
  }
}
