import inView from 'in-view';
import SmoothScroll from 'smooth-scroll';

export default class InViewEvent {
  constructor() {
    this.init();
  }

  init() {
    // inView.threshold(0.2);
    inView('.js-inView')
      .on('enter', function (el) {
        el.classList.add('js-inViewAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });
    inView('.js-inViewB')
      .on('enter', function (el) {
        el.classList.add('js-inViewBAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });
    inView('.js-inViewC')
      .on('enter', function (el) {
        el.classList.add('js-inViewCAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAct');
      });

    inView('.js-inViewAlpha')
      .on('enter', function (el) {
        el.classList.add('js-inViewAlphaAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewAlphaAct');
      });

    inView('.js-inViewSL')
      .on('enter', function (el) {
        el.classList.add('js-inViewSLAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewSLAct');
      });
    inView('.js-CountUp').on('enter', function (el) {
      if (el.classList.contains('Counted')) {
        return false;
      }
      el.classList.add('Counted');
      countUP(el, 10);
    });

    inView('.js-CountUpT').on('enter', function (el) {
      if (el.classList.contains('Counted')) {
        return false;
      }
      el.classList.add('Counted');
      countUP(el, 100);
    });

    inView('.js-inViewSplit')
      .on('enter', function (el) {
        setTimeout(() => {
          el.classList.add('js-inViewSplitAct');
        }, 620);
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewSplitAct');
      });

    inView('.js-inViewMatrixA ')
      .on('enter', function (el) {
        el.classList.add('js-inViewMatrixAAct');
      })
      .on('exit', (el) => {
        // el.classList.remove('js-inViewMatrixAAct');
      });

    const Roll = document.querySelector('.topReadRoll');
    inView('.topRead')
      .on('enter', function (el) {
        Roll.classList.add('BgDown');
      })
      .on('exit', (el) => {
        // Roll.classList.remove('BgDown');
      });

    const RollAbout = document.querySelector('.aboutReadRoll');
    inView('.aboutRead')
      .on('enter', function (el) {
        RollAbout.classList.add('BgDown');
      })
      .on('exit', (el) => {
        // Roll.classList.remove('BgDown');
      });

    // inView('.topRead').on('exit', function (el) {
    //   if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    //     if (window.scrollY > window.innerHeight + 100) {
    //       var scroll = new SmoothScroll();
    //       var anchor = document.querySelector('.topMemberInner');
    //       let options = {
    //         speed: 50,
    //         durationMin: 550,
    //         easing: 'easeInOutCubic',
    //         updateURL: false,
    //       };
    //       scroll.animateScroll(anchor, 0, options);
    //     }
    //   }
    // });
    // inView('.topWhat').on('exit', function (el) {
    //   var clientRect = el.getBoundingClientRect();
    //   var y = clientRect.top;
    //   console.log(y);
    //   if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    //     if (0 < y) {
    //       var scroll = new SmoothScroll();
    //       var anchor = document.querySelector('.topMemberInner');
    //       let options = {
    //         speed: 200,
    //         durationMin: 200,
    //         easing: 'easeInCubic',
    //       };
    //       scroll.animateScroll(anchor, 0, options);
    //     }
    //   }
    // });
  }
}

function countUP(el, unit) {
  const DURATION = 600;
  const from = parseInt(el.dataset.from, 10);
  let to = parseInt(el.dataset.to, 10);
  const startTime = Date.now();
  let toCount = 0;
  if (to < 10) {
    toCount = 10;
  } else {
    toCount = to;
  }
  const timer = setInterval(() => {
    const elapsedTime = Date.now() - startTime;
    const progress = elapsedTime / DURATION;
    if (progress < 1) {
      let Num = Math.floor(from + progress * (toCount - from));
      el.textContent = Num;
    } else {
      el.textContent = to;
      clearInterval(timer);
    }
  }, 5);
}
